import _ from 'lodash';
import React from 'react';
import moment from "moment";
import {Tag} from "antd";

import {colors} from "../../../lib/theme";
import {TranslationContentTable} from "../../../components/TranslationContentTable/TranslationContentTable";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'USP Description',
        dataIndex: 'customFields',
        key: 'usps',
        render: (customFields) => _.truncate(_.get(customFields, 'uspDescription', ''), { length: 50 }),
    },
    {
        title: 'Long Text',
        dataIndex: 'customFields',
        key: 'longText',
        render: (customFields) => _.truncate(_.get(customFields, 'longDescription', ''), { length: 50 }),
    },
]

export const AdminTranslationProductPropertyGroups = ({user, authenticated, history}) => {

    return (
        <TranslationContentTable
            type={'property-group'}
            jobLogTypes={['translate-property-group', 'translate-property-groups', 'translate-property-group-option', 'translate-property-group-options']}
            capitalName={'Product Property Groups'}
            functionName={'ProductPropertyGroups'}
            columns={columns}
            user={user}
            history={history}
        />
    )

};
