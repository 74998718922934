import React from 'react';
import styled from 'styled-components';
import {Button, Select, Tag} from 'antd';
import {
    CheckCircleFilled,
    ExclamationCircleOutlined,
    InfoCircleFilled,
    ReloadOutlined,
    WarningFilled
} from '@ant-design/icons';

import {CardContent, CardHeader, CardWrapper } from "../../components/Layout/Layout";
import {useActiveJobLogs} from "../../hooks/useActiveJobLogs";
import {colors} from "../../lib/theme";
import {getDuration} from "../../lib/utils";

const JobLogMessagesContainer = styled.div`
    background-color: #0a2429;
    padding: 16px;
    border-radius: 6px;
`;
const ChildJobContainer = styled.div`
    background-color: #f2f2f2;
    padding-left: 20px;
    border-radius: 6px;
    margin-top: 16px;
`;
const JobLogMessage = styled.div`
    color: #fff;
    font-family: "Courier New";
    margin-bottom: 2px;
`;
const InfoIcon = styled(InfoCircleFilled)`
    color: ${colors.BLUE};
`;
const WarningIcon = styled(WarningFilled)`
    color: ${colors.YELLOW};
`;
const ErrorIcon = styled(ExclamationCircleOutlined)`
    color: ${colors.RED};
`;
const SuccessIcon = styled(CheckCircleFilled)`
    color: ${colors.GREEN};
`;

const Option = Select.Option;


const JobLogMessagesContainerContent = ({ jobLog }) => {
    return jobLog.messages && jobLog.messages.length > 0 ? (
        <JobLogMessagesContainer>
            {(jobLog.messages || []).map(message => {
                const icon = message.type === 'success' ? <SuccessIcon/> : message.type === 'info' ? <InfoIcon/> : message.type === 'warning' ? <WarningIcon/> : <ErrorIcon/>;
                return (
                    <JobLogMessage key={message.objectId}>[{(message.recordedAt ? message.recordedAt.iso : message.createdAt).split('.')[0].replace('T', ' ')}]: {icon} {['success', 'error'].includes(message.type) ? (<b>{message.message}</b>) : message.message}</JobLogMessage>
                )
            })}
        </JobLogMessagesContainer>
    ) : null;
}
const SubJobLogContent = ({ jobLog, onFetchJobLogMessages }) => {
    return (
        <CardContent key={jobLog.objectId}>
            <div>
                <Tag color={colors.SECONDARY}>SUB {jobLog.type}</Tag>
                {getDuration(jobLog.createdAt)}
            </div>
            <h2>{jobLog.description}</h2>
            <p>{jobLog.completed ? (<Tag color='green'>COMPLETED</Tag>) : (<Tag color='orange'>RUNNING</Tag>)} | {jobLog.currentProcess} of {jobLog.processCount} completed | {jobLog.createdAt} | <a onClick={() => onFetchJobLogMessages(jobLog.objectId)}>See Logs</a> </p>

            <JobLogMessagesContainerContent jobLog={jobLog}/>
        </CardContent>
    )
}
const JobLogContent = ({ jobLog, onFetchJobLogMessages }) => {
    return (
        <CardContent key={jobLog.objectId}>
            <div>
                <Tag color={colors.SECONDARY}>PARENT {jobLog.type}</Tag>
                {getDuration(jobLog.createdAt)}
            </div>
            <h2>{jobLog.description}</h2>
            <p>{jobLog.completed ? (<Tag color='green'>COMPLETED</Tag>) : (<Tag color='orange'>RUNNING</Tag>)} | {jobLog.currentProcess} of {jobLog.processCount} completed | {jobLog.createdAt} | <a onClick={() => onFetchJobLogMessages(jobLog.objectId)}>See Logs</a> </p>

            <JobLogMessagesContainerContent jobLog={jobLog}/>

            {jobLog.children && jobLog.children.length ? (
                <ChildJobContainer>
                    {jobLog.children.map(childLog => <SubJobLogContent key={childLog.objectId} jobLog={childLog} onFetchJobLogMessages={onFetchJobLogMessages}/>)}
                </ChildJobContainer>
            ) : null}
        </CardContent>
    )
}

export const JobLogList = ({user, fetchPrevious = true, fetchActive = false, types = [], limit }) => {
    const { previousJobLogs = [], refresh: reloadPreviousJobLogs, fetchMessages: fetchJobLogMessages } = useActiveJobLogs({
        user,
        fetchPrevious,
        fetchActive,
        types,
        limit,
    });

    return (
        <CardWrapper>
            <CardHeader>
                <h2>Previous Jobs</h2>
                <Select
                    placeholder='Job Types'
                    defaultValue={''}
                    onChange={() => {}}
                    style={{ width: '300px' }}
                >
                    {types.map((type) => (
                        <Option key={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
                <Button type='primary' icon={<ReloadOutlined />} onClick={reloadPreviousJobLogs}>
                    Refresh
                </Button>
            </CardHeader>
            {previousJobLogs.map((jobLog) => {
                return <JobLogContent key={jobLog.objectId} jobLog={jobLog} onFetchJobLogMessages={fetchJobLogMessages}/>;
            })}
        </CardWrapper>
    );
};
