import React, {useRef, useCallback, useEffect, useState} from 'react';
import { notification, Select, Tag} from "antd";
import * as Parse from "parse";

const { Option } = Select;


const languageColorMap = {
  'en': 'red',
  'fr': 'blue',
  'nl': 'orange',
}

export const TranslationMethodSelection = ({ targetLang, onSelect }) => {

  const [loading, setLoading] = useState(false);

  const [translationMethods, setTranslationMethods] = useState([]);
  const [selectedTranslationMethodId, setSelectedTranslationMethodId] = useState('');
  const allTranslationMethodsRef = useRef([]);

  const fetchTranslationMethods = useCallback(async () => {
    setLoading(true);
    try {
      const translationMethods = await Parse.Cloud.run(`adminFetchConfigParam`, {
        key: 'translationMethods'
      });
      allTranslationMethodsRef.current = translationMethods;
      setTranslationMethods(allTranslationMethodsRef.current);
      updateTranslationMethodSelection(targetLang);
    } catch (error) {
      notification.error({
        message: 'Cannot get translation methods',
      });
    }
    setLoading(false);
  }, [targetLang]);

  useEffect(() => {
    fetchTranslationMethods();
  }, []);

  const updateTranslationMethodSelection = (language) => {
    // Only show methods that work with the currently selected target language
    const availableMethods = allTranslationMethodsRef.current.filter(m => m.languages.includes(language));
    setTranslationMethods(availableMethods);
    // Set default method for current target languages
    const selectedMethod = allTranslationMethodsRef.current.find(m => m.languages.includes(language) && m.default === true);
    if (selectedMethod) {
      setSelectedTranslationMethodId(selectedMethod.id);
    }
  }

  useEffect(() => {
    updateTranslationMethodSelection(targetLang);
  }, [targetLang]);


  const handleTranslationMethodSelect = (id) => {
    setSelectedTranslationMethodId(id);
    onSelect(id);
  };

  return (
      <Select
          placeholder='Select translation method'
          value={selectedTranslationMethodId}
          onChange={handleTranslationMethodSelect}
          style={{ width: '280px'}}
          loading={loading}
      >
        {translationMethods.map((method) => (
            <Option key={method.id}>
              {method.languages.map(l => <Tag color={languageColorMap[l]} key={l}>{l}</Tag>)} {method.name}
            </Option>
        ))}
      </Select>
  );
};
