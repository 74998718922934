
import React, { Component } from 'react';

import { OrdersFulfillmentTableByFilters } from '../../components/OrdersFulfillmentTableByFilters/OrdersFulfillmentTableByFilters';
import {CardWrapper, Container, ContentWrapper} from "../../components/Layout/Layout";


const filterTags = {
  b2b: true, // Auto filter by origin name
};
const fulfillmentFilterTags = {};
const warehouses = [];

export class OrderB2b extends Component {
  componentDidMount() {}

  render() {
    const { user } = this.props;
    return (
      <Container>
        <ContentWrapper>
          <CardWrapper>
            <OrdersFulfillmentTableByFilters
              title={`Orders B2B`}
              user={user}
              filterTags={filterTags}
              fulfillmentFilterTags={fulfillmentFilterTags}
              warehouses={warehouses}
              showOrderStatistics={false}
            />
          </CardWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
