import React from 'react';
import {Tag, Breadcrumb} from "antd";

import {colors} from "../../../lib/theme";
import {TranslationContentTable} from "../../../components/TranslationContentTable/TranslationContentTable";

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Visible',
        dataIndex: 'visible',
        key: 'visible',
        render: (value) => {
            return value ? (
                <Tag color={colors.PRIMARY}><small>TRUE</small></Tag>
            ) : (
                <Tag color={colors.GREY}><small>FALSE</small></Tag>
            )
        }
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
        render: (value) => {
            return value ? (
                <Tag color={colors.PRIMARY}><small>TRUE</small></Tag>
            ) : (
                <Tag color={colors.GREY}><small>FALSE</small></Tag>
            )
        }
    },
    {
        title: 'Path',
        dataIndex: 'breadcrumb',
        key: 'breadcrumb',
        render: (breadcrumb = []) => {
            return (
                <Breadcrumb separator='>'>
                    {breadcrumb.map((name) => <Breadcrumb.Item key={name}>{name}</Breadcrumb.Item>)}
                </Breadcrumb>
            );
        }
    },
]

export const AdminTranslationCategories = ({user, authenticated, history}) => {

    return (
        <TranslationContentTable
            type={'category'}
            jobLogTypes={['translate-categories', 'translate-category']}
            capitalName={'Category'}
            functionName={'Categories'}
            columns={columns}
            user={user}
            history={history}
        />
    )

};
