export const routes = {
  LOGIN: '/',

  DASHBOARD: '/dashboard',

  USER_PROFILE: '/user/profile',

  ORDER_OVERVIEW: '/order-overview',
  ORDER_OVERVIEW_DETAILS: '/order-overview/:orderId',
  ORDER_FULFILLMENT: '/order-fulfillment',
  ORDER_FULFILLMENT_BY_PLENTY_ID: '/order-fulfillment/plenty/:plentyOrderId',
  ORDER_FULFILLMENT_DETAILS: '/order-fulfillment/:orderId',
  ORDER_FULFILLMENT_BATCH_DETAILS: '/order-fulfillment-batch',
  ORDER_FULFILLMENT_BY_WAREHOUSE: '/order-fulfillment/warehouse/:warehouseId',
  ORDER_FULFILLMENT_BY_WAREHOUSE_DETAILS: '/order-fulfillment/warehouse/:warehouseId/:orderId',
  ORDER_PRODUCTION_BY_BRAND: '/order-production/brand/:brandId',
  ORDER_PRODUCTION_BY_BRAND_DETAILS: '/order-production/brand/:brandId/:orderId',
  ORDER_PRODUCTION: '/order-production',
  ORDER_PRODUCTION_DETAILS: '/order-production/:orderId',
  ORDER_COLOR_CARDS: '/order-color-cards',
  ORDER_COLOR_CARDS_DETAILS: '/order-color-cards/:orderId',
  ORDER_EXTERNAL: '/order-external',
  ORDER_EXTERNAL_DETAILS: '/order-external/:orderId',
  ORDER_BOUNCED: '/order-bounced',
  ORDER_BOUNCED_DETAILS: '/order-bounced/:orderId',
  ORDER_MARKETPLACE: '/order-marketplace',
  ORDER_MARKETPLACE_DETAILS: '/order-marketplace/:orderId',
  ORDER_B2B: '/order-b2b',
  ORDER_B2B_DETAILS: '/order-b2b/:orderId',
  ORDER_CH: '/order-ch',
  ORDER_CH_DETAILS: '/order-ch/:orderId',
  ORDER_FULFILLMENT_TRANSFER: '/order-fulfillment-transfer',
  ORDER_FULFILLMENT_TRANSFER_DETAILS: '/order-fulfillment-transfer/:orderId',
  ORDER_SEARCH: '/order-fulfillment-search',
  ORDER_SEARCH_DETAILS: '/order-fulfillment-search/:orderId',

  ADMIN_USERS: '/admin/users',
  ADMIN_USERS_EDIT: '/admin/users/:userId',
  ADMIN_EMAIL_TEMPLATES: '/admin/email-templates',
  ADMIN_EMAIL_TEMPLATES_EDIT: '/admin/email-templates/:id',
  ADMIN_CONFIG_PARAMS: '/admin/config-params',
  ADMIN_CONFIG_PARAMS_EDIT: '/admin/config-params/:id',
  ADMIN_PRODUCT_LIST: '/admin/product-list',
  ADMIN_SHOPWARE_SYNC: '/admin/shopware-sync',
  ADMIN_SHOP_CUSTOMER_SYNC: '/admin/shop-customer-sync',
  ADMIN_PRODUCT_IMAGES: '/admin/product-images',
  ADMIN_CUSTOMER_SUPPORT_CART_LINK: '/admin/customer-support-cart-link/:id',
  ADMIN_CUSTOMER_SEARCH: '/admin/customer-search',
  ADMIN_CUSTOMER_SEARCH_DETAIL: '/admin/customer-search/:id',
  ADMIN_IMPORT_ORDER_TRANSACTIONS: '/admin/order-transaction-import',

  ADMIN_TRANSLATION_PRODUCTS: '/admin/translations/products',
  ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUPS: '/admin/translations/product-property-groups',
  ADMIN_TRANSLATION_PRODUCT_PROPERTY_GROUP_OPTIONS: '/admin/translations/product-property-group-options',
  ADMIN_TRANSLATION_CATEGORIES: '/admin/translations/categories',
  ADMIN_TRANSLATION_PAGES: '/admin/translations/pages',
  ADMIN_TRANSLATION_GLOSSARIES: '/admin/translations/glossaries',
  ADMIN_TRANSLATION_GLOSSARIES_EDIT: '/admin/translations/glossaries/:id',
};
