import React, {useState} from 'react';
import * as Parse from 'parse';

import {notification, Input, Row, Col, Checkbox, Avatar} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

import {CardContent, CardHeader, CardWrapper, Container, ContentWrapper, Spacer} from "../../components/Layout/Layout";
import {JobLogList} from "../../components/JobLogList/JobLogList";

const {Search} = Input;

const jobLogTypes = ['shopware-product-import', 'plenty-item-sync'];
export const ShopwareSync = ({user, authenticated, history}) => {
    const [loading, setLoading] = useState(false);

    const syncProduct = async (name) => {
        setLoading(true);
        try {
            const result = await Parse.Cloud.run('shopwareConnectorPlentyProductAll', {
                itemName: name,
            });
        } catch (error) {
            notification.error({
                message: 'Cannot import products',
            });
        }
        setLoading(false);
    };
    const syncOrder = async (orderId) => {
        setLoading(true);
        try {
            await Parse.Cloud.run('shopwareConnectorSyncOrder', {
                orderId,
            });
            notification.success({
                message: 'Order synced',
            });
        } catch (error) {
            notification.error({
                message: 'Cannot sync order',
            });
        }
        setLoading(false);
    };
    const syncOrderPayment = async (orderId) => {
        setLoading(true);
        try {
            await Parse.Cloud.run('shopwareConnectorSyncOrderPayment', {
                orderId,
            });
            notification.success({
                message: 'Order payment synced',
            });
        } catch (error) {
            notification.error({
                message: 'Cannot sync order payment',
            });
        }
        setLoading(false);
    };
    const tinifyImage = async (imageUrl) => {
        setLoading(true);
        try {
            await Parse.Cloud.run('shopwareTinifyImage', {
                imageUrl,
            });
            notification.success({
                message: 'Image tinified',
            });
        } catch (error) {
            notification.error({
                message: 'Cannot tinify image',
            });
        }
        setLoading(false);
    };

    const syncPlentyProduct = async (itemName) => {
        setLoading(true);
        try {
            await Parse.Cloud.run('adminRunPlentyProductSync', {
                itemName,
            });
            notification.success({
                message: 'Syncing Plenty Product',
            });
        } catch (error) {
            notification.error({
                message: 'Cannot sync Plenty Product',
            });
        }
        setLoading(false);
    };

    return (
        <Container>
            <ContentWrapper>

                <CardWrapper>
                    <CardHeader><h2>Shopware</h2></CardHeader>
                    <CardContent>
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}>
                                <h3>Products</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Search
                                    placeholder='Product name'
                                    enterButton='Sync'
                                    prefix={<SearchOutlined/>}
                                    onSearch={(value) => syncProduct(value)}
                                    loading={loading}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col md={6} sm={24}>
                                <h3>Image Tinify</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Search
                                    placeholder='Image Url'
                                    enterButton='Tinify'
                                    prefix={<SearchOutlined/>}
                                    onSearch={(value) => tinifyImage(value)}
                                    loading={loading}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col md={6} sm={24}>
                                <h3>Order</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Search
                                    placeholder='Order Id'
                                    enterButton='Sync'
                                    prefix={<SearchOutlined/>}
                                    onSearch={(value) => syncOrder(value)}
                                    loading={loading}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col md={6} sm={24}>
                                <h3>Order Payment</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Search
                                    placeholder='Order Id'
                                    enterButton='Sync'
                                    prefix={<SearchOutlined/>}
                                    onSearch={(value) => syncOrderPayment(value)}
                                    loading={loading}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </CardWrapper>

              <Spacer/>

                <CardWrapper>
                    <CardHeader><h2>Plenty / Logistics</h2></CardHeader>
                    <CardContent>
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}>
                                <h3>Plenty Products</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Search
                                    placeholder='Product name'
                                    enterButton='Sync'
                                    prefix={<SearchOutlined/>}
                                    onSearch={(value) => syncPlentyProduct(value)}
                                    loading={loading}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </CardWrapper>


                <Spacer/>

                <JobLogList
                    fetchPrevious={true}
                    fetchActive={false}
                    user={user}
                    types={jobLogTypes}
                />

            </ContentWrapper>
        </Container>
    );
};
