import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as Parse from 'parse';

import { useWindowFocus } from './useWindowFocus';
import moment from "moment";

export const useActiveJobLogs = ({ user, fetchActive = true, fetchPrevious = false, types = [], limit = 50}) => {
  const jobLogsRef = useRef();
  const [jobLogs, setJobLogs] = useState([]);
  const [previousJobLogs, setPreviousJobLogs] = useState([]);
  const queryRef = useRef();
  const querySubscriptionRef = useRef();
  const windowFocussed = useWindowFocus();

  const updateItem = async (nextItem) => {
    jobLogsRef.current = jobLogsRef.current.map((item) => {
      if (`${nextItem.objectId}` === nextItem.objectId) {
        return nextItem;
      }
      return item;
    });
    setJobLogs(jobLogsRef.current);
  };

  const cancelById = async (jobLogId) => {
    const JobLog = Parse.Object.extend('JobLog');
    const query = new Parse.Query(JobLog);
    query.equalTo('objectId', jobLogId);
    const jobLog = await query.first({});
    if (jobLog) {
      jobLog.set('completed', true);
      await jobLog.save(null, {});
    }
  };

  const addItem = async (nextItem) => {
    // Check if job already in list
    const findJobLog = jobLogs.find((item) => item.objectId === nextItem.objectId);
    if (findJobLog) {
      return;
    }
    // Add to ref and state
    jobLogsRef.current = [...jobLogsRef.current, nextItem];
    setJobLogs(jobLogsRef.current);
  };

  const removeItem = async (removedItem) => {
    // Add to ref and state
    jobLogsRef.current = jobLogsRef.current.filter((item) => item.objectId !== removedItem.objectId);
    setJobLogs(jobLogsRef.current);
  };

  const appendItemToPrevious = (item) => {
    setPreviousJobLogs([item, ...previousJobLogs]);
  };

  const subscribe = async () => {
    if (!queryRef.current) {
      return;
    }
    querySubscriptionRef.current = await queryRef.current.subscribe();
    querySubscriptionRef.current.on('create', (object) => {
      console.log('JobLog created', object);
      addItem(object.toJSON());
    });
    querySubscriptionRef.current.on('update', (object) => {
      console.log('JobLog updated', object);
      addItem(object.toJSON());
      updateItem(object.toJSON());
    });
    querySubscriptionRef.current.on('enter', (object) => {
      console.log('JobLog entered', object);
    });
    querySubscriptionRef.current.on('leave', (object) => {
      console.log('JobLog left', object);
      removeItem(object.toJSON());
      appendItemToPrevious(object.toJSON());
    });
    querySubscriptionRef.current.on('delete', (object) => {
      // console.log('JobLog deleted', object);
    });
    querySubscriptionRef.current.on('close', () => {
      // console.log('JobLog subscription closed');
    });
  };

  const unsubscribe = async () => {
    if (!querySubscriptionRef.current) {
      return;
    }
    querySubscriptionRef.current.unsubscribe();
    querySubscriptionRef.current = null;
  };

  useEffect(() => {
    return () => unsubscribe();
  }, []);

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }
    await unsubscribe();

    const JobLog = Parse.Object.extend('JobLog');
    queryRef.current = new Parse.Query(JobLog);
    // Create parse user from current user
    const ParseUser = Parse.Object.extend('_User');
    const parseUser = new ParseUser();
    parseUser.id = user.objectId;
    queryRef.current.equalTo('user', parseUser);
    if (types && types.length) {
      queryRef.current.containedIn('type', types);
    }
    queryRef.current.notEqualTo('completed', true);
    queryRef.current.notEqualTo('error', true);
    queryRef.current.notEqualTo('success', true);
    queryRef.current.descending('createdAt');
    const queryResult = await queryRef.current.find({});
    // Keep ref for updates via subscription
    jobLogsRef.current = queryResult.map((jobLog) => jobLog.toJSON());
    setJobLogs(jobLogsRef.current);

    // Subscribe to query
    await subscribe();
  }, [jobLogs, setJobLogs, subscribe, user, types.join()]);

  useEffect(() => {
    if (fetchActive) {
      fetchData();
    }
  }, [user, fetchActive]);

  useEffect(() => {
    if (windowFocussed) {
      fetchData();
    }
  }, [windowFocussed]);

  const fetchPreviousData = useCallback(async () => {
    const JobLog = Parse.Object.extend('JobLog');
    const query = new Parse.Query(JobLog);
    if (user) {
      // Create parse user from current user
      const ParseUser = Parse.Object.extend('_User');
      const parseUser = new ParseUser();
      parseUser.id = user.objectId;
      query.equalTo('user', parseUser);
    }
    if (types && types.length) {
      query.containedIn('type', types);
    }
    query.limit(limit || 20);
    query.greaterThanOrEqualTo('createdAt', moment().hour(0).minute(0).second(0).toDate());
    query.descending('createdAt');
    const queryResult = await query.find({});
    const allJobs = queryResult.map((jobLog) => jobLog.toJSON());
    const parentJobs = allJobs.filter(job => !job.parentId || !job.parentId.length);
    const childJobs = allJobs.filter(job => job.parentId && job.parentId.length > 0);
    const nestedJobs = parentJobs.map((job) => {
      const children = childJobs.filter((child) => child.parentId === job.objectId);
      return {
        ...job,
        children: children || [],
      };
    });
    setPreviousJobLogs(nestedJobs);
  }, [setPreviousJobLogs, user, types.join(), limit]);

  const fetchJobLogMessages = useCallback(async (jobId) => {
    const JobLog = Parse.Object.extend('JobLog');
    const jobLog = new JobLog();
    jobLog.id = jobId;

    const JobLogMessage = Parse.Object.extend('JobLogMessage');
    const query = new Parse.Query(JobLogMessage);
    query.equalTo('jobLog', jobLog);
    query.limit(500);
    query.ascending('recordedAt');
    const queryResult = await query.find({});

    const nextPreviousJobLogs = previousJobLogs.map((job) => {
      if (job.objectId === jobId) {
        job.messages = queryResult.map(m => m.toJSON());
      }
      (job.children || []).forEach((childJob) => {
        if (childJob.objectId === jobId) {
          childJob.messages = queryResult.map(m => m.toJSON());
        }
      })
      return job;
    });

    setPreviousJobLogs(nextPreviousJobLogs);

  }, [setPreviousJobLogs, previousJobLogs]);

  useEffect(() => {
    if (fetchPrevious) {
      fetchPreviousData();
    }
  }, [user, fetchPrevious]);

  return { jobLogs, previousJobLogs, cancelById, refresh: fetchPreviousData, fetchMessages: fetchJobLogMessages };
};
