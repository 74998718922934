import React, {useEffect, useState} from 'react';
import * as Parse from 'parse';

import {notification, Input, Row, Col, Checkbox, Button} from 'antd';

import {CardContent, CardHeader, CardWrapper, Container, ContentWrapper, Spacer} from "../../components/Layout/Layout";
import {JobLogList} from "../../components/JobLogList/JobLogList";

export const ShopCustomerSync = ({user, authenticated, history}) => {
    const [loading, setLoading] = useState(false);

    const [vmeCustomerSync, setVmeCustomerSync] = useState({ mainId: '', emailAddress: '', mergeId1: '', mergeId2: '', mergeId3: '', mergeId4: '', mergeId5: '', forceImport: false });

    const syncVmeCustomer = async (syncData) => {
        if (!syncData.mainId || !syncData.mainId.length) {
            return;
        }
        setLoading(true);
        try {
            const result = await Parse.Cloud.run('adminSyncPlentyCustomerToShopwareVME', {
                customerId: syncData.mainId,
                mergeCustomerIds: [
                    syncData.mergeId1,
                    syncData.mergeId2,
                    syncData.mergeId3,
                    syncData.mergeId4,
                    syncData.mergeId5,
                ].filter(id => id && id.length > 0),
                emailAddress: syncData.emailAddress,
                forceImport: syncData.forceImport,
            });
        } catch (error) {
            notification.error({
                message: 'Cannot import customer! See Logs',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        console.log('vmeCustomerSync changed', vmeCustomerSync);
    }, [vmeCustomerSync]);

    return (
        <Container>
            <ContentWrapper>

                <CardWrapper>
                    <CardHeader><h2>VME Customer Sync</h2></CardHeader>
                    <CardContent>
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}>
                                <h3>Main ID*</h3>
                            </Col>
                            <Col md={18} sm={24}>
                                <Input
                                    placeholder='Insert Plenty customer ID'
                                    value={vmeCustomerSync.mainId}
                                    onChange={({ target: { value }}) => setVmeCustomerSync({...vmeCustomerSync, mainId: value })}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}>
                                <h4>Email Address (optional)</h4>
                            </Col>
                            <Col md={18} sm={24}>
                                <Input
                                    placeholder='Insert valid email address for the invite'
                                    value={vmeCustomerSync.emailAddress}
                                    onChange={({ target: { value }}) => setVmeCustomerSync({...vmeCustomerSync, emailAddress: value })}
                                    style={{ width: '100%' }}
                                    type={'email'}
                                />
                                <small>By default the plenty account email address is used.</small>
                            </Col>
                        </Row>
                        {['1','2','3','4','5'].map((mergeNumber) => {
                            return (
                                <Row gutter={[16, 16]} key={mergeNumber}>
                                    <Col md={6} sm={24}>
                                        <h4>Merge ID {mergeNumber} (optional)</h4>
                                    </Col>
                                    <Col md={18} sm={24}>
                                        <Input
                                            placeholder='Insert Plenty customer ID'
                                            value={vmeCustomerSync[`mergeId${mergeNumber}`]}
                                            onChange={({ target: { value }}) => setVmeCustomerSync({...vmeCustomerSync, [`mergeId${mergeNumber}`]: value })}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}><h4>Force Sync?</h4></Col>
                            <Col md={18} sm={24}>
                                <Checkbox onChange={({ target: { checked }}) => setVmeCustomerSync({...vmeCustomerSync, forceImport: checked })} checked={vmeCustomerSync.forceImport}>
                                    If the customer was already synced before, it will be deleted and synced again with the new email address.
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col md={6} sm={24}></Col>
                            <Col md={18} sm={24}>
                                <Button onClick={() => syncVmeCustomer(vmeCustomerSync)} loading={loading} disabled={loading} type='primary'>
                                    Sync Customer now
                                </Button>
                            </Col>
                        </Row>
                    </CardContent>
                </CardWrapper>

                <Spacer/>

                <JobLogList
                    fetchPrevious={true}
                    fetchActive={true}
                    types={['customer-plenty-sw-sync-vme']}
                    user={user}
                />

            </ContentWrapper>
        </Container>
    );
};
